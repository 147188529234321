import { css } from "goober";

export const ContentContainer = () => css`
  display: grid;
  grid-auto-rows: auto;
  gap: var(--sp-4);
`;

export const Cols = () => css`
  display: flex;
  align-items: flex-start;
  gap: var(--sp-4);

  @container content-container (inline-size <= 700px) {
    display: grid;
    grid-auto-rows: auto;
  }

  .aside {
    flex-basis: 20.5rem;

    .panel {
      display: grid;
      grid-auto-rows: auto;
      gap: var(--sp-3);
    }
  }
  .main-col {
    flex: 2;
    display: grid;
    grid-auto-rows: auto;
    gap: var(--sp-4);
  }
`;

export const DiceOdds = () => css`
  container-type: inline-size;
  container-name: dice-odds;

  .content {
    @container dice-odds (inline-size <= 560px) {
      overflow-x: scroll;
    }
  }

  header {
    background: var(--shade7);
    border-top-left-radius: var(--br-lg);
    border-top-right-radius: var(--br-lg);
    border-bottom: 1px solid var(--shade3-25);

    .col {
      color: var(--shade1);
    }
  }

  .row {
    display: flex;
    padding-inline: var(--sp-5);

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--shade3-15);
    }

    @container dice-odds (inline-size <= 560px) {
      padding-inline: var(--sp-2);
      width: 560px;
    }
  }
  .col {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    padding-block: var(--sp-2);
  }
  .meta {
    flex-basis: 4rem;
    gap: var(--sp-2);
  }
  .val {
    justify-content: flex-end;
  }
  .unit-name {
    width: 10ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .pips {
    position: absolute;
    display: flex;
    gap: 2px;
    bottom: 0;
    right: 0;
    opacity: var(--percent-opacity, 1);

    @container percent-breakdown (inline-size <= 560px) {
      gap: 1px;
    }
  }
  .pip {
    height: 2px;
    width: var(--sp-3);
    background: var(--percent-color, var(--perf-pos2));

    @container percent-breakdown (inline-size <= 640px) {
      width: var(--sp-3);
    }
  }
`;

export const NoSelection = () => css`
  display: flex;
  align-items: center;
  gap: var(--sp-6);
  color: var(--shade1);
  padding: var(--sp-12);
`;

export const Caption = () => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-2);

  .meta {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    color: var(--shade1);
  }

  span {
    max-width: 43ch;
  }
  .units {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
  }

  @container content-card (inline-size <= 560px) {
    flex-direction: column;
  }
`;
