import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import UnitImg from "@/game-tft/ChampionImage.jsx";
import UnitsGroups from "@/game-tft/CompsFilterChampions.jsx";
import { getCurrentSet } from "@/game-tft/constants.mjs";
import {
  Caption,
  Cols,
  ContentContainer,
  DiceOdds,
  NoSelection,
} from "@/game-tft/GuideLoadedDice.style.jsx";
import { URLS } from "@/game-tft/guides-constants.mjs";
import { StringToHighlight } from "@/game-tft/InGameShared.jsx";
import { MIN_LEVEL_LOADED_DICE } from "@/game-tft/loaded-dice-constants.mjs";
import {
  getLoadedDiceTable,
  getPips,
  getUnitsByUnitTable,
  oddsPipColor,
  oddsPipOpacity,
  oddsTextColor,
} from "@/game-tft/loaded-dice-utils.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const UNIT_IMG_SIZE = 32;
const FORMAT = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
  style: "percent",
};

const LoadedDice = () => {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const state = useSnapshot(readState);
  const skinSetting = state.settings.tft.isSkinsEnabled;
  const currentSet = getCurrentSet();
  const allUnits = state?.tft[currentSet]?.champions;
  const items = state.tft[currentSet]?.items;

  const { loadedDiceTable } = useMemo(() => {
    const loadedDiceTable = getLoadedDiceTable({
      champStatic: allUnits,
      selectedSet: currentSet,
      itemStatic: items,
      // skipDuplicates: true,
    });

    return { loadedDiceTable };
  }, [allUnits, items, currentSet]);

  const unit = searchParams.get("champion");
  const currentUnitTable = loadedDiceTable[unit];
  const [bestUnits, bestLevel] = getUnitsByUnitTable({
    units: currentUnitTable,
    champions: allUnits,
    set: currentSet,
    skipDuplicates: true,
  });

  const renderedUnitTable =
    currentUnitTable &&
    Object.entries(currentUnitTable)
      .map(([unitKey, vals]) => ({
        unitKey,
        vals: (vals || []).map((val) => Number(val) / 100),
      }))
      .sort((a, b) => {
        return (
          b.vals[bestLevel - MIN_LEVEL_LOADED_DICE] -
          a.vals[bestLevel - MIN_LEVEL_LOADED_DICE]
        );
      });

  return (
    <PageContainer>
      <PageHeader
        title={t("tft:guidesLoadedDiceTool", "Loaded Dice Tool - Guides")}
        links={URLS}
      />
      <div className={ContentContainer()}>
        <section className={Cols()}>
          <Card classNameOuter="aside" className="panel">
            <UnitsGroups allowed="all" multiSelect={false} hideRepeatUnits />
          </Card>
          <div className="main-col">
            {allUnits?.[unit]?.name && (
              <Card>
                <div className={Caption()}>
                  <div className="meta">
                    <img
                      src={`${appURLs.CDN}/blitz/tft/items/loadeddice.webp`}
                      alt={t("tft:loadedDice", "Loaded Dice")}
                      width="46"
                      height="46"
                    />
                    <span className="type-caption">
                      <Trans i18nKey="overlays:tft.useLoadedDice">
                        At level <StringToHighlight source={bestLevel} />, use
                        loaded dice on these units for the best chance of
                        getting{" "}
                        <StringToHighlight source={allUnits[unit].name} />.
                      </Trans>
                    </span>
                  </div>
                  <div className="units">
                    {bestUnits.map((unit) => (
                      <UnitImg
                        key={unit.id}
                        size={UNIT_IMG_SIZE}
                        champKey={unit.key}
                        cost={allUnits?.[unit.key]?.cost}
                        skinSetting={skinSetting}
                        set={currentSet}
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            <Card padding="0">
              {renderedUnitTable?.length ? (
                <div className={DiceOdds()}>
                  <div className="content">
                    <header className="row">
                      {new Array(renderedUnitTable[0].vals.length + 1)
                        .fill(0)
                        .map((_, i) => (
                          <div
                            key={i}
                            className={`col type-overline ${
                              i === 0 ? "meta" : "val"
                            }`}
                          >
                            {i === 0
                              ? t("lol:champion", "Champion")
                              : t(
                                  `tft:lvl${i + MIN_LEVEL_LOADED_DICE - 1}`,
                                  `Lvl ${i + MIN_LEVEL_LOADED_DICE - 1}`,
                                )}
                          </div>
                        ))}
                    </header>
                    <ol>
                      {renderedUnitTable.map((unit) => (
                        <li key={unit.unitKey} className="row">
                          <div className="col meta">
                            <UnitImg
                              key={unit.id}
                              size={UNIT_IMG_SIZE}
                              champKey={unit.unitKey}
                              cost={allUnits?.[unit.unitKey]?.cost}
                              skinSetting={skinSetting}
                              set={currentSet}
                            />
                            <span className="unit-name type-caption--bold">
                              {allUnits?.[unit.unitKey]?.name}
                            </span>
                          </div>
                          {unit.vals.map((val, i) => (
                            <div
                              key={i}
                              className="col val type-caption"
                              style={{
                                color: oddsTextColor(val),
                                "--percent-opacity": oddsPipOpacity(val),
                                "--percent-color": oddsPipColor(val),
                              }}
                            >
                              <span>
                                {val.toLocaleString(getLocale(), FORMAT)}
                              </span>
                              <div className="pips">
                                {new Array(getPips(val)).fill(0).map((_, i) => (
                                  <div key={i} className="pip" />
                                ))}
                              </div>
                            </div>
                          ))}
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              ) : (
                <div className={NoSelection()}>
                  <img
                    src={`${appURLs.CDN}/blitz/tft/items/loadeddice.webp`}
                    alt={t("tft:loadedDice", "Loaded Dice")}
                    width="52"
                    height="52"
                  />
                  <p type="type-body1">
                    {t(
                      "tft:pickUnitToSeeLoadedDiceOdds",
                      "Pick a unit to to see the units with the best odds for rolling your chosen unit using the Loaded Dice item.",
                    )}
                  </p>
                </div>
              )}
            </Card>
          </div>
        </section>
      </div>
    </PageContainer>
  );
};

export default LoadedDice;

export function meta(_params, searchParams) {
  let set = searchParams?.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: [
      "tft:meta.loadedDice.title",
      "TFT Loaded Dice Tool - TFT Guides - Set {{set}}",
      { set },
    ],
    description: [
      "tft:meta.loadedDice.description",
      "Unlock Winning Strategies with the Loaded Dice Tool in TFT! Optimize Comps, Builds, and Meta Using Key Stats.",
      { set },
    ],
  };
}
